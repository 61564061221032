
















import { Component, Vue } from "vue-property-decorator";
import InvoicesResource from "@/resources/invoices-resource";
import { InvoiceForm } from "@/data/invoice";

@Component
export default class InvoiceCreationView extends Vue {
  loading: boolean = false;
  form = new InvoiceForm();

  created(): void {
    if (this.$route.query.amount) {
      this.form.amount = this.$route.query.amount as string;
    }
  }
  async createInvoice() {
    await this.$validator.validate("form.*").then(async (res: boolean) => {
      if (res) {
        this.loading = true;
        InvoicesResource.create(this.form)
          .then(res => {
            this.$router.push({
              name: "invoice-confirm",
              params: {
                code: res.guid
              }
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    });
  }
}
